import React from "react";
import { Link } from 'react-router-dom'

function TitleSale() {
  return (
    <div className="mt-16">
      <noscript>You need to enable JavaScript to run this app.</noscript>
      <div className="col-lg-12 text-center bg-gray-200 p-3">
        {/* <Link to="/" className="inline-block align-middle">
            <img src="https://1NPSHOP.COM/logo192.png" alt="1NPSHOP.COM" className="w-10 h-10" />
            </Link> */}
        <h1 className="inline align-middle text-3xl font-bold text-blue-700 ml-2">
          {/* ໝວກແຟຊັ່ນງາມແລະມີຄຸນນະພາບ */}NP Shop
          <br />
          <span className="text-yellow-500">⭐⭐⭐⭐⭐</span>
        </h1>
        <br />
        <Link to="/contact" className="text-blue-700 font-bold text-sm">
          NP Shop, Nongbone Road, Phonxay Village, Saysettha District, Vientiane
          Capital, Laos
        </Link>
      </div>
    </div>
  );
}

export default TitleSale;
