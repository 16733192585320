import React, { useState } from "react";
import axios from "axios";
import Carousel from "./Carousel";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PayMentModal from "./PayMentModal";
import moment from "moment";
import html2canvas from 'html2canvas';

function FormBuy(props) {
  const [data, setData] = useState({
    fullname: "",
    phone_number: "",
    address: "",
    notes: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [content, setContent] = useState('');
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [invoice, setInvoice] = useState(null);
  const renderStatus = (status)=> {
    switch (status) {
      case 1:
        return props.properties.WaitingForProgressing;
      case 2:
        return props.properties.Processing;
      case 3:
        return props.properties.Delivering;
      case 4:
        return props.properties.Delivered;
      default:
        return status;
    }
  }
  const renderPayment = (status)=> {
    switch (status) {
      case 1:
        return props.properties.Unpaid;
      case 2:
        return props.properties.WaitingForProgressing;
      case 3:
        return props.properties.Paid;
      case 4:
        return props.properties.Failure;
      default:
        return status;
    }
  }
  // chụp màng hình
  const captureScreen = () => {
    const element = document.getElementById('capture'); // ID của phần tử bạn muốn chụp
    html2canvas(element,{allowTaint:true, useCORS:true}).then(canvas => {
      const imgData = canvas.toDataURL('image/png');

      // Tạo một link để tải ảnh xuống
      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = `NP_${invoice?.singular}.png`; // Tên của tệp tin singular
      downloadLink.click();
    });
  };

  const handleButtonClick = async() => {
    console.log("first")
    const fetchData = async() =>{
      try{
          const res = await axios.get(`${props.domain}/api/singular_invoice/`)
          console.log(res)
          setContent(res.data.data.singular)
      }catch(error){
          console.log(error)
      }
    }
    if (count===0){
      await fetchData();
      setCount(count+1)
    }
    
    setModalOpen(!modalOpen);
  };
  const handlClick = async (qr) => {
    
    if (qr && !file){
      console.log(qr)
      toast.error(props.properties.alert_file_image_not_null)
      return 
    }
    const formData = new FormData();
    formData.append('address', data.address);
    formData.append('phone_number', data.phone_number);
    formData.append('notes', data.notes);
    formData.append('full_name', data.fullname);
    formData.append('singular', content);
    formData.append('file', file || ''); // Đặt tên của file tại đây
    formData.append('total_amount', (parseFloat(props.getQuantity_product().quantity) > 0 ? parseFloat(props.getQuantity_product().quantity) : 1) * props.product.price_discount);
    formData.append('data', JSON.stringify([
      {
        id: props.getQuantity_product().product.id,
        count: parseInt((parseFloat(props.getQuantity_product().quantity) > 0 ? parseFloat(props.getQuantity_product().quantity) : 1)),
      }
    ]));
    
    if (
      data.address === "" ||
      data.phone_number === "" ||
      data.fullname === ""
    ) {
      toast.error(props.properties.pleaseEnter);
    } else {
      try {
        const response = await axios.post(
          `${props.domain}/api/invoice/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Định dạng của dữ liệu là form data
            },
          }
        );
        if (response.status === 201) {
          toast.success(props.properties.alert);
          setInvoice(response.data.data)
          setShow(true)
          setCount(0)
          setModalOpen(false)
          setData({
            fullname: "",
            phone_number: "",
            address: "",
            notes: "",
          });
          props.setQuantity(1);
          props.scrollToTop();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <div
        id="cart"
        className="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300 "
      >
        <>
      {show && (
        <div className="fixed inset-0 flex items-center justify-center z-50" id="capture">
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div className="bg-white rounded-lg p-8 z-50">
            <div className="flex justify-end">
              <button className="text-gray-600 hover:text-gray-900" onClick={handleClose}>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <h2 className="text-xl font-semibold mb-4 ">{props.properties.order_detail}</h2>
            <div
              className="mb-5 overflow-hidden rounded-lg shadow-lg bg-white"
            >
              <div className="px-4 py-3 border-b">
                <div className="text-lg font-semibold text-blue-800">
                  {invoice?.fullname}
                </div>
                <div className="text-gray-700">{invoice?.phone_number}</div>
              </div>
              <div className="p-4">
                <div className="mb-2">
                  <div className="text-sm text-gray-600">
                    {props.properties.payTotalOnline}
                  </div>
                  <div className="text-xl font-semibold text-green-700">
                    {new Intl.NumberFormat("la-LA", {
                          style: "currency",
                          currency: "LAK",
                          minimumFractionDigits: 0, // This will not show any decimal places
                          maximumFractionDigits: 0, // This ensures that even if there are decimals, they are not shown
                        }).format(
                          (parseFloat(invoice?.total_amount) > 0 ?  parseFloat(invoice?.total_amount):0))}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-sm text-gray-600">
                    {props.properties.paymentStatus}
                  </div>
                  <div className="text-md">
                    {renderPayment(invoice?.payment_status)}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-sm text-gray-600">
                    {props.properties.conditionOrder}
                  </div>
                  <div className="text-md">{renderStatus(invoice?.condition)}</div>
                </div>
                <div className="text-sm text-gray-600">
                  {props.properties.dateOrder}
                </div>
                <div className="text-md">
                  {moment(invoice?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleClose}>{props.properties.close}</button>
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={()=>captureScreen()}>{props.properties.save_invoice}</button>
            </div>
          </div>
        </div>
      )}
    </>
        <ToastContainer 
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <h1 className="text-6xl font-medium my-2">{props.properties.buyNow}</h1>
        <div action="" className="my-10">
          <div className="flex flex-col space-y-5">
            <label htmlFor="fullname">
              <p className="font-medium text-slate-700 pb-2">
                {props.properties.fullName}
              </p>
              <input
                id="fullname"
                name="fullname"
                value={data.fullname}
                type="text"
                onChange={(e) => setData({ ...data, fullname: e.target.value })}
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder={props.properties.enter + " " + props.properties.fullName}
                required
              />
            </label>
            <label htmlFor="Phone_number">
              <p className="font-medium text-slate-700 pb-2">
                {props.properties.phoneNumber}
              </p>
              <input
                id="Phone_number"
                name="Phone_number"
                value={data.phone_number}
                onChange={(e) =>
                  setData({ ...data, phone_number: e.target.value })
                }
                type="text"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder={props.properties.enter + " " + props.properties.phoneNumber}
                required
              />
            </label>
            <label htmlFor="address">
              <p className="font-medium text-slate-700 pb-2">
                {props.properties.address}
              </p>
              <input
                id="address"
                name="address"
                type="text"
                value={data.address}
                onChange={(e) => setData({ ...data, address: e.target.value })}
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder={props.properties.enter + " " + props.properties.address}
                required
              />
            </label>
            <label htmlFor="note">
              <p className="font-medium text-slate-700 pb-2">
                {props.properties.notes}
              </p>
              <input
                id="notes"
                name="notes"
                type="text"
                value={data.notes}
                onChange={(e) => setData({ ...data, notes: e.target.value })}
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder={props.properties.notes}
              />
            </label>
            <label htmlFor="total">
            <div className="row flex flex-col space-y-4">
                  {props.product && (
                    <div className="bg-white shadow rounded-lg overflow-hidden">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            {props.product.image && props.product.image[0]?.image && (
                              <img
                                className="h-20 w-20 object-cover rounded-full"
                                src={`${props.domain + props.product.image[0].image}`}
                                alt=""
                              />
                            )}
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {props.product.name}
                            </p>
                            <p className="text-sm text-gray-500 truncate">
                              {props.properties.numberofcounts} : {props.getQuantity_product().quantity > 0 ? props.getQuantity_product().quantity:1}
                            </p>
                            <p className="text-sm font-medium text-gray-900 flex flex-col">
                              <span className="text-red-500 line-through">{new Intl.NumberFormat("la-LA", {
                                style: "currency",
                                currency: "LAK",
                              }).format(parseFloat(props.getQuantity_product().quantity * props.product.price))}</span>
                              <span className="font-bold text-indigo-600 ">{new Intl.NumberFormat("la-LA", {
                                style: "currency",
                                currency: "LAK",
                              }).format(parseFloat(props.getQuantity_product().quantity * props.product.price_discount))}</span>
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="text-red-500 line-through">
                                {new Intl.NumberFormat("la-LA", {
                                  style: "currency",
                                  currency: "LAK",
                                  minimumFractionDigits: 0, // This will not show any decimal places
                                  maximumFractionDigits: 0, // This ensures that even if there are decimals, they are not shown
                                }).format(
                                  (parseFloat(props.getQuantity_product().quantity) > 0 ?  parseFloat(props.getQuantity_product().quantity):1)* props.product.price
                                )}
                              </span>
                              <span className="font-bold text-indigo-600 ml-2">
                                {new Intl.NumberFormat("la-LA", {
                                  style: "currency",
                                  currency: "LAK",
                                  minimumFractionDigits: 0, // This will not show any decimal places
                                  maximumFractionDigits: 0, // This ensures that even if there are decimals, they are not shown
                                }).format(
                                  (parseFloat(props.getQuantity_product().quantity) > 0 ?  parseFloat(props.getQuantity_product().quantity):1)* props.product.price_discount
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

            </label>
            <div>
              <button
                onClick={()=>handlClick()}
                className="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
              >
                <span>{props.properties.buyNow}</span>
              </button>
              <button id="openModal" onClick={()=>handleButtonClick()} className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 w-full mt-5 rounded-lg">
                {props.properties.payMentButton}
              </button>
            </div>
            <PayMentModal image={image} properties={props.properties} setImage={setImage} content={content} product={props.product} getQuantity_product={()=>props.getQuantity_product()} domain={props.domain} modalOpen={modalOpen} setFile={setFile} handlClick={handlClick}/>
            <Carousel
              listProduct={props.listProduct}
              domain={props.domain}
              config={props.properties}
              scrollToTop={props.scrollToTop}
            />
          </div>
        </div>
      </div>
    </>
  );
}


export default FormBuy;
