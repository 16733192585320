import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="col-lg-4 text-center bg-gray-200 p-3 bottom-0 w-full">
      <span className="mr-1">
        Copyright
        {new Date().getFullYear()}
        © 
        <Link to="/" className="">
          NP Shopping Cart
        </Link>
        All Rights Reserved |
        Version <b className="text-green-800">1.0.1</b> | Powered By{' '}
        <Link target="_blank" rel="noopener noreferrer" to="https://t.me/letiennhat">
          NP DEVELOPMENT
        </Link>
        Designed by{' '}
        <Link className="text-warning text-blue-700 font-weight-semi-bold" to="https://t.me/letiennhat">
          NP Development
        </Link>
      </span>
    </footer>
  );
}

export default Footer;
