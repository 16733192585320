import React, { useState, useEffect, useRef } from 'react';

const SpinWheel = () => {
  const [isRotating, setIsRotating] = useState(false);
  const [message, setMessage] = useState('');
  const wheelRef = useRef(null);
  const [giftWon, setGiftWon] = useState(null);

  const listGift = [
    { text: 'I phone 13', percent: 0.1 , colors:'red'},
    { text: 'Siêu xe', percent: 0.1 , colors:'violet'},
    { text: 'Coc', percent: 0.1 , colors:'blue'},
    { text: 'OI', percent: 0.1 , colors:'black'},
    { text: 'Man', percent: 0.1 , colors:'green'},
    { text: 'Chinh', percent: 0.1 , colors:'#69FFFF'},
    { text: 'Sau', percent: 0.1 , colors:'#FF86FF'},
    // Khai báo thêm các phần thưởng ở đây...
  ];
  const startRotation = () => {
    if (isRotating) return;

    setIsRotating(true);
    setMessage('');
    const random = Math.random();
    const gift = getGift(random);
    const spins = 3; // Số lần quay vòng
    const totalDegrees = spins * 360 + gift.percent * 360;

    wheelRef.current.style.transition = 'transform 4s ease-out';
    wheelRef.current.style.transform = `rotate(${totalDegrees}deg)`;

    setTimeout(() => {
      setIsRotating(false);
      setMessage(`Chúc mừng bạn đã nhận được "${gift.text}"`);
      setGiftWon(true);
    }, 4000); // Thời gian quay và hiển thị thông báo
  };

  useEffect(() => {
    if (!isRotating) {
      wheelRef.current.style.transition = 'none';
      wheelRef.current.style.transform = 'rotate(0deg)';
    }
  }, [isRotating]);

  const getGift = (randomNumber) => {
    let currentPercent = 0;
    for (let gift of listGift) {
      currentPercent += gift.percent;
      if (randomNumber <= currentPercent) {
        return gift;
      }
    }
    return listGift[listGift.length - 1]; // Trả về phần thưởng cuối cùng nếu không có gì khớp
  };
  // Hàm đóng modal
  const closeModal = () => {
    setGiftWon(null);
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <main className="text-center">
        <div className="relative inline-block">
          <ul
            ref={wheelRef}
            className="wheel relative m-4 inline-block w-64 h-64 border-8 border-red-900 rounded-full list-none"
            style={{ width: '320px', height: '320px', position: 'relative' }}
          >
            {listGift.map((gift, index) => (
              <li
                key={index}
                className={`absolute top-0 right-0 w-1/2 h-1/2`}
                style={{
                  transformOrigin: '0% 100%',
                //   backgroundColor: colors[index % colors.length],
                  transform: `rotate(${index * (360 / listGift.length)}deg)`,
                }}
              >
                <p
                  className={`absolute w-full h-full flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2 text-black font-bold`}
                  style={{
                    backgroundColor: index % 2 === 0 ? 'bg-blue-600' : 'bg-blue-300',
                    transform: `rotate(${-index * (360 / listGift.length)}deg)`,
                    color: gift.colors
                  }}
                >
                  {gift.text}
                </p>
              </li>
            ))}
          </ul>
          <button
            onClick={startRotation}
            className={`mt-4 px-6 py-2 text-white font-bold rounded-full ${
              isRotating
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            disabled={isRotating}
          >
            Quay thưởng
          </button>
        </div>
        {giftWon && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-8 m-4 max-w-xs max-h-full text-center">
            <h3 className="mb-4 text-lg font-bold">Chúc mừng bạn đã trúng thưởng!</h3>
            <p className="mb-4">{message}</p>
            <button
              onClick={closeModal}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Đóng
            </button>
          </div>
        </div>
      )}
      </main>
    </div>
  );
};

export default SpinWheel;
