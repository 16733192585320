import React, {useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,faCreditCard,faMoneyBill,faComment,faUser,faBuildingColumns
} from "@fortawesome/free-solid-svg-icons";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import axios from "axios";
function PayMentModal(props) {
    const [card, setCard] = useState(null);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onload = () => {
            props.setImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
        if (file && file.type.startsWith('image/')) {
          props.setFile(file);
        } else {
          toast.error(props.properties.alert_select_image); 
          props.setFile(null);
        }
      };
    const copyToClipboard = (text, content) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                if (content){
                    toast.success(props.properties.alert_coppy_content)
                }else{
                    toast.success(props.properties.alert_coppy);
                }
                
            })
            .catch(err => {
                toast.success(props.properties.alert_coppy_err)
            });
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${props.domain}/api/accountpayment/`);
                setCard(res.data.data[0]);
            } catch (error) {
                toast.error(props.properties.alert_erro_invoice)
            }
        };

        fetchData();
    }, []);


  return (
<div className="flex items-center justify-center">
           {props?.modalOpen && (
                <>
                <section className="body-font  text-gray-600 mb-4">
                    <div className="container mx-auto flex max-w-3xl flex-wrap justify-center rounded-lg bg-white px-5 py-24">
                    {/* QR Code Number Account & Uploadfile */}
                    <div className="flex-wrap md:flex">
                        <div className="mx-auto">
                        <img className="mx-auto h-52 w-52 rounded-lg border p-2 md:mt-0" src={`${props.domain}${card?.image}`} alt="step" />
                        <div>
                            <h1 className="font-laonoto mt-4 mb-3 text-center text-xl font-bold">{props.properties.account_number_card}</h1>
                            <p className="ml-14 mb-3 text-start font-bold text-gray-600"> <span className='text-black'><FontAwesomeIcon icon={faBuildingColumns} /></span> {props.properties.Bank_name}: {card?.bank_name}</p>
                            <p className="ml-14 mb-3 text-start font-bold text-gray-600"> <span className='text-black'><FontAwesomeIcon icon={faUser} /></span> {props.properties.Account_name}: {card?.name}</p>
                            <p className="ml-14 mb-3 text-start font-medium text-blue-800 cursor-pointer hover:cursor-pointer" onClick={()=>copyToClipboard(card?.card_number)}> <span className='text-black'><FontAwesomeIcon icon={faCreditCard} /> {props.properties.Account}: </span> {card?.card_number} <FontAwesomeIcon icon={faCopy} /></p>
                            <p className="ml-14 mb-3 text-start font-medium text-red-500 cursor-pointer hover:cursor-pointer"> <span className='text-black'><FontAwesomeIcon icon={faMoneyBill} /> {props.properties.totalPrice}: </span> 
                                {new Intl.NumberFormat("la-LA", {
                                  style: "currency",
                                  currency: "LAK",
                                  minimumFractionDigits: 0, // This will not show any decimal places
                                  maximumFractionDigits: 0, // This ensures that even if there are decimals, they are not shown
                                }).format(
                                  (parseFloat(props.getQuantity_product().quantity) > 0 ?  parseFloat(props.getQuantity_product().quantity):1)* props.product.price_discount
                            )}</p>
                            <p className="ml-14 mb-3 text-start text-red-500 cursor-pointer hover:cursor-pointer font-bold" onClick={()=>copyToClipboard(props.content, 'content')}> <span className='text-black'><FontAwesomeIcon icon={faComment} /> {props.properties.content}: </span> {props.content} <FontAwesomeIcon icon={faCopy} /></p>
                        </div>
                        {/* component */}
                        <div className="mx-auto w-52">
                            <div className="m-4">
                            <div className="flex w-full items-center justify-center">
                                {props.image === null ? (<label className="flex h-14 w-full cursor-pointer flex-col border-4 border-dashed border-gray-200 hover:border-gray-300 hover:bg-gray-100">
                                    <div className="mt-4 flex items-center justify-center space-x-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 text-gray-400">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                        </svg>
                                        <p className="font-laonoto text-sm tracking-wider text-gray-400 group-hover:text-gray-600">{props.properties.upload_image}</p>
                                    </div>
                                    <input type="file" className="opacity-0" onChange={handleFileChange}/>
                                </label>):(
                                <div className="mt-4 pb-10">
                                    <img src={props.image} alt="Uploaded" className="w-full" />
                                    <label className="flex h-14 w-full cursor-pointer flex-col border-4 border-dashed border-gray-200 hover:border-gray-300 hover:bg-gray-100">
                                            <div className="mt-4 flex items-center justify-center space-x-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 text-gray-400">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                                </svg>
                                                <p className="font-laonoto text-sm tracking-wider text-gray-400 group-hover:text-gray-600">{props.properties.upload_image}</p>
                                            </div>
                                            <input type="file" className="opacity-0" onChange={handleFileChange}/>
                                        </label>
                                </div>
                                )}
                            </div>
                            </div>
                        </div>
                        <button className="mx-auto block rounded-md border bg-blue-500 px-6 py-2 text-white outline-none mb-10" onClick={()=>props.handlClick('qr')}>{props.image === null ? props.properties.button_upload_payment:props.properties.buyNow}</button>
                        </div>
                        {/* Step Checkout */}
                        <div className="mt-10 max-w-sm md:mt-0 md:ml-10 md:w-2/3">
                        <div className="relative flex pb-12">
                            <div className="absolute inset-0 flex h-full w-10 items-center justify-center">
                            <div className="pointer-events-none h-full w-1 bg-gray-200" />
                            </div>
                            <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="h-5 w-5" viewBox="0 0 24 24">
                                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                            </svg>
                            </div>
                            <div className="flex-grow pl-4">
                            <h2 className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">{props.properties.STEP_1}</h2>
                            <p className="font-laonoto leading-relaxed">
                            {props.properties.Pay_with_Scan_Pay} <br />
                                <b>{props.properties.qr_code}</b>{props.properties.payment_via_account_number}
                            </p>
                            </div>
                        </div>
                        <div className="relative flex pb-12">
                            <div className="absolute inset-0 flex h-full w-10 items-center justify-center">
                            <div className="pointer-events-none h-full w-1 bg-gray-200" />
                            </div>
                            <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="h-5 w-5" viewBox="0 0 24 24">
                                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                            </svg>
                            </div>
                            <div className="flex-grow pl-4">
                            <h2 className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">{props.properties.STEP_2}</h2>
                            <p className="font-laonoto leading-relaxed">{props.properties.Provide_proof}<b>
                                    {props.properties.image_invoice}</b>.</p>
                            </div>
                        </div>
                        <div className="relative flex pb-12">
                            <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="h-5 w-5" viewBox="0 0 24 24">
                                <circle cx={12} cy={5} r={3} />
                                <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3" />
                            </svg>
                            </div>
                            <div className="flex-grow pl-4">
                            <h2 className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">{props.properties.STEP_3}</h2>
                            <p className="font-laonoto leading-relaxed">
                                {props.properties.contentStep3}<span> <b><Link to='/invoice'>{props.properties.order_information}</Link></b></span>.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                </>
            )}
    </div>

  );
}

export default PayMentModal;
