import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationDot,
    faPhone,
    faEnvelope,
    faPaperPlane,
    faF,
    faJ,
    faSquarePhone
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import contact_json from '../config_contact.json'
function Contact() {
  return (
    <div className="relative min-h-screen flex flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white shadow sm:rounded-lg ">
          <div className="bg-blue-100 p-5 text-black flex justify-center items-center flex-col">
            <h3 className="text-3xl font-bold text-center text-blue-900 mb-4">Information Contact</h3>
            <div className="space-y-2">
              {/* Make sure you wrap each icon and text in a div and apply flex */}
              <div className="flex items-center"><FontAwesomeIcon icon={faLocationDot} className="text-blue-900 mr-2" /> {contact_json.lable_location}: <span className='ml-1'> {contact_json.address}</span></div>
              <div className="flex items-center"><FontAwesomeIcon icon={faPhone} className="text-blue-900 mr-2" /> {contact_json.lable_phone_number}: <span className='ml-1'>{contact_json.phone}</span></div>
              <div className="flex items-center"><FontAwesomeIcon icon={faEnvelope} className="text-blue-900 mr-2" />{contact_json.lable_email}: <span className='ml-1'>{contact_json.email}</span></div>
              <Link to={`${contact_json.linkFace}`} className="flex items-center"><FontAwesomeIcon icon={faF} className="text-blue-900 mr-2" />{contact_json.lable_face} : <span className='ml-1'>{contact_json.nameFace}</span></Link>
              <Link to={`${contact_json.linkTele}`} className="flex items-center"><FontAwesomeIcon icon={faPaperPlane} className="text-blue-900 mr-2" />{contact_json.lable_telegram}: <span className='ml-1'> {contact_json.telegram}</span></Link>
              <Link to={`${contact_json.linkTiktok}`} className="flex items-center"><FontAwesomeIcon icon={faJ} className="text-blue-900 mr-2" /> {contact_json.lable_tiktok}: <span className='ml-1'>{contact_json.nameTiktok}</span></Link>
              <Link to={`${contact_json.linkwhapapp}`} className="flex items-center"><FontAwesomeIcon icon={faSquarePhone} className="text-blue-900 mr-2" /> {contact_json.lable_what_app}: <span className='ml-1'>{contact_json.whatapp}</span></Link>
            </div>
          </div>

          {/* Map section */}
          <div>
            <iframe
              title="Our Location"
              className="w-full h-64 sm:h-96"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16079.834231877343!2d102.61878844480403!3d17.967688841608503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312467007c608e6d%3A0x948f64640d9500f2!2sNP%20Shop!5e0!3m2!1sen!2sla!4v1715141086568!5m2!1sen!2sla"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
