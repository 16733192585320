import config_json from './config.json';

const properties = {
    title: config_json.title,
    buyNow:config_json.buyNow,
    fullName: config_json.fullName,
    phoneNumber:config_json.phoneNumber,
    address:config_json.address,
    notes:config_json.notes,
    hours:config_json.hours,
    minutes:config_json.minutes,
    seconds:config_json.seconds,
    chooseQuantity:config_json.chooseQuantity,
    enter:config_json.enter,
    customerFeedback:config_json.customerFeedback,
    titleReview: config_json.titleReview,
    titlefeedbackComment:config_json.titlefeedbackComment,
    nameImage:config_json.nameImage,
    nameProduct:config_json.nameProduct,
    totalQuantity:config_json.totalQuantity,
    price:config_json.price,
    totalPrice:config_json.totalPrice,
    alert:config_json.alert,
    currencyUnit:config_json.currencyUnit,
    numberofcounts:config_json.numberofcounts,
    domain:config_json.domain,
    mainDomain : config_json.mainDomain,
    prev:config_json.prev,
    next:config_json.next,
    numberSoldOfProduct:config_json.numberSoldOfProduct,
    numberInStock:config_json.numberInStock,
    numberView:config_json.numberView,
    numberRating:config_json.numberRating,
    pleaseEnter:config_json.pleaseEnter,
    phoneNumberContact: config_json.phoneNumberContact,
    timeUp:config_json.timeUp,
    PaymentStatus:config_json.PaymentStatus,
    Condition:config_json.Condition,
    Date:config_json.Date,
    alert_invoice :config_json.alert_invoice,
    WaitingForProgressing:config_json.WaitingForProgressing,
    Processing:config_json.Processing,
    Delivering:config_json.Delivering,
    Delivered:config_json.Delivered,
    Unpaid:config_json.Unpaid,
    Paid:config_json.Paid,
    Failure:config_json.Failure,
    nameSearchInvoice:config_json.nameSearchInvoice,
    placeholderInvoice:config_json.placeholderInvoice,
    alert_coppy:config_json.alert_coppy,
    alert_coppy_err:config_json.alert_coppy_err,
    alert_erro_invoice:config_json.alert_erro_invoice,
    account_number_card:config_json.account_number_card,
    upload_image:config_json.upload_image,
    button_upload_payment:config_json.button_upload_payment,
    STEP_1:config_json.STEP_1,
    Pay_with_Scan_Pay: config_json.Pay_with_Scan_Pay,
    payment_via_account_number:config_json.payment_via_account_number,
    STEP_2:config_json.STEP_2,
    Provide_proof:config_json.Provide_proof,
    image_invoice:config_json.image_invoice,
    STEP_3:config_json.STEP_3,
    contentStep3:config_json.contentStep3,
    order_information:config_json.order_information,
    alert_select_image:config_json.alert_select_image,
    Account:config_json.Account,
    Account_name:config_json.Account_name,
    Bank_name:config_json.Bank_name,
    content:config_json.content,
    alert_coppy_content:config_json.alert_coppy_content,
    alert_file_image_not_null : config_json.alert_file_image_not_null,
    qr_code:config_json.qr_code,
    Add_to_cart:config_json.Add_to_cart,
    payMentButton:config_json.payMentButton,
    order_detail:config_json.order_detail,
    close:config_json.close,
    save_invoice:config_json.save_invoice,
    conditionOrder:config_json.conditionOrder,
    paymentStatus:config_json.paymentStatus,
    home:config_json.home,
    product:config_json.product,
    contact: config_json.contact,
    filter:config_json.filter,
    price_filter:config_json.price_filter,
    search:config_json.search,
    category:config_json.category,
    from:config_json.From,
    to:config_json.to,
    filter_product:config_json.filter_product,
    vietnam: config_json.vietnam,
    thailan:config_json.thailan,
    TrungQuoc :config_json.TrungQuoc,
    lao:config_json.lao,
    Tienganh:config_json.Tienganh,
    Selectlanguage:config_json.Selectlanguage
}

export default properties