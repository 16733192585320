// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Phetsarath OT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Phetsarath OT';
}
/* Đặt trong file CSS của bạn */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3600deg); /* 10 vòng quay */
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,4BAA4B;EAC5B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,4BAA4B;AAC9B;AACA,+BAA+B;AAC/B;EACE;IACE,uBAAuB;EACzB;EACA;IACE,0BAA0B,EAAE,iBAAiB;EAC/C;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Phetsarath OT';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Phetsarath OT';\n}\n/* Đặt trong file CSS của bạn */\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(3600deg); /* 10 vòng quay */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
