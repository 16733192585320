import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function Invoice(props) {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const renderStatus = (status) =>{
    switch (status) {
      case 1:
        return props.properties.WaitingForProgressing;
      case 2:
        return props.properties.Processing;
      case 3:
        return props.properties.Delivering;
      case 4:
        return props.properties.Delivered;
      default:
        return status;
    }
  }
  const renderPayment = (status) =>{
    switch (status) {
      case 1:
        return props.properties.Unpaid;
      case 2:
        return props.properties.WaitingForProgressing;
      case 3:
        return props.properties.Paid;
      case 4:
        return props.properties.Failure;
      default:
        return status;
    }
  }
  const submit = async () => {
    try {
      const res = await axios.get(
        `${props.properties.domain}/api/detailinvoice/?search=${search}`
      );
      console.log(res?.data?.data);
      if (res.status) {
        setData(res.data.data);
      } else {
        setData([]);
      }
      if (!res.data.data.length) {
        toast.warning(props.properties.alert_invoice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container max-w-2xl mx-auto mt-10">
        <h1 className="text-2xl font-semibold">
          {props.properties.conditionOrder}
        </h1>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
          >
            {props.properties.nameSearchInvoice}
          </label>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              type="search"
              onChange={(e) => setSearch(e.target.value)}
              id="default-search"
              className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder={props.properties.placeholderInvoice}
              required
            />
            <button
              type="submit"
              onClick={() => submit()}
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {props.properties.nameSearchInvoice}
            </button>
          </div>
        </div>
      </div>
      {/* component */}
      <section className="container mx-auto p-6 font-mono mb-32">
        {data &&
          data.map((item, index) => (
            <div
              className="mb-5 overflow-hidden rounded-lg shadow-lg bg-white"
              key={index}
            >
              <div className="px-4 py-3 border-b">
                <div className="text-lg font-semibold text-blue-800">
                  {item?.fullname}
                </div>
                <div className="text-gray-700">{item?.phone_number}</div>
              </div>
              <div className="p-4">
                <div className="mb-2">
                  <div className="text-sm text-gray-600">
                    {props.properties.payTotalOnline}
                  </div>
                  <div className="text-xl font-semibold text-green-700">
                    {item?.total_amount} {props.properties.currencyUnit}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-base text-gray-600">
                    <strong>{props.properties.paymentStatus}</strong>
                  </div>
                  <div className="text-sm">
                    {renderPayment(item?.payment_status)}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-base text-gray-600">
                    <strong>{props.properties.conditionOrder}</strong>
                  </div>
                  <div className="text-sm">{renderStatus(item?.condition)}</div>
                </div>
                <div className="text-sm text-gray-600">
                  {props.properties.dateOrder}
                </div>
                <div className="text-md">
                  {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
            </div>
          ))}
      </section>
    </>
  );
}


export default Invoice;
