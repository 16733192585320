import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import the Link component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight
} from "@fortawesome/free-solid-svg-icons";
function Carousel({ listProduct, domain, config, scrollToTop }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const item = listProduct[currentIndex];
  useEffect(() => {
    // Reset to the first product when the list changes
    setCurrentIndex(0);
  }, [listProduct]);

  const goToPrev = () => {
    console.log("goToPrev");
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : listProduct.length - 1
    );
    
  };

  const goToNext = () => {
    console.log("goToNext");
    setCurrentIndex((prevIndex) =>
      prevIndex < listProduct.length - 1 ? prevIndex + 1 : 0
    );
  };


  return (
    <div className="relative flex">
      <div className="flex overflow-hidden">
        <button
          onClick={goToPrev}
          style={{"left": '-30px'}}
          className="absolute top-24 left-0 z-10 bg-slate-400 rounded-full text-white h-10 w-10  hover:bg-blue-600 mb-20"
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
        {item && (
          <div className="flex-none w-full">
            <Link to={`/${item.slug}`} onClick={()=>scrollToTop()}>
              <img
                className="rounded-lg h-64 object-contain w-full"
                src={`${domain}${item.image?.[0]?.image}`}
                alt={item.name}
              />
              <div className="py-4 px-8 bg-white rounded-lg shadow-lg">
                <h1 className="mt-2 text-gray-900 font-bold text-2xl tracking-tight">
                  {item.name}
                </h1>
                <p className="py-3 text-gray-600 leading-6">
                  {item.description}
                </p>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <span className="text-red-500 line-through font-bold text-xl">
                    {config?.currencyUnit} {item.price_str} 
                    </span>
                    <span className="font-bold text-indigo-600 text-xl ">{config?.currencyUnit} {item.price_discount_str}</span>
                  </div>
                  <button className="bg-red-600 text-white py-3 px-3 rounded-lg">
                    {config?.Add_to_cart}
                  </button>
                </div>
              </div>
            </Link>
          </div>
        )}
        <button
          onClick={goToNext}
          className="absolute top-24 right-0 z-10 bg-slate-400 text-white h-10 w-10 rounded-full hover:bg-blue-600 mb-20"
          style={{'right': '-30px'}}
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>
    </div>
  );
}

export default Carousel;
