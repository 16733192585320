import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Star from "./Star";
function ProductDetail(props) {
  // Khởi tạo trước với giá trị mặc định
  const [selectedImage, setSelectedImage] = useState("");
  const [lightboxDisplay, setLightboxDisplay] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  // Dùng useEffect để cập nhật `selectedImage` khi `props.product.image` thay đổi
  useEffect(() => {
    if (props.product.image && props.product.image.length > 0) {
      setSelectedImage(props.product.image[0]); // Giả sử ảnh đầu tiên làm ảnh được chọn mặc định
      setImageIndex(0); // Reset index về 0
    }
    document.title = `${props.product.name} | ${props.properties.title}`;
  }, [props.product.image, props.product.name]); // Phụ thuộc vào props.product.image

  // Sử dụng images từ props, và fallback là mảng rỗng nếu không tồn tại
  const images = props.product.image ? props.product.image : [];

  const openLightbox = (index) => {
    setImageIndex(index);
    setSelectedImage(images[index]);
    console.log(images[index]);
    setLightboxDisplay(true);
  };

  const moveToNext = () => {
    const newIndex = (imageIndex + 1) % images.length;
    setImageIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  const moveToPrev = () => {
    const newIndex = (imageIndex - 1 + images.length) % images.length;
    setImageIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };
  // Handler for changing the main image
  const handleThumbnailClick = (image) => {
    props.setMainImage(`${props.domain}${image}`);
  };

  if (!props.product) {
    return (
      <div className="text-center py-6">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (props.mainImage === "") {
    if (props.product.image && props.product.image.length > 0) {
      props.setMainImage(`${props.domain}${props.product.image[0].image}`);
    } else {
      return (
        <div className="text-center py-6">
          <h1>No image available</h1>
        </div>
      );
    }
  }

  return (
    <>
      <div className="py-6">
        {lightboxDisplay && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
            <button
              onClick={moveToPrev}
              className="absolute left-2 text-white rounded-full bg-slate-500 h-10 w-10"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            <img
              src={`${props.domain}${selectedImage.image}`}
              className="max-w-full max-h-full"
              alt=""
            />
            <button
              onClick={moveToNext}
              className="absolute right-2 text-white rounded-full bg-slate-500 h-10 w-10"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
            <button
              onClick={() => setLightboxDisplay(false)}
              className="absolute text-white top-10 right-10 rounded-full bg-slate-700 h-10 w-10"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        )}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 ">
          <div className="flex flex-col md:flex-row -mx-4">
            <div className="md:flex-1 px-4">
              <div>
                <div className="h-64 md:h-80 rounded-lg bg-gray-100 mb-4 flex justify-content-center align-item-center">
                  <img
                    onClick={() => openLightbox(0)}
                    src={props.mainImage}
                    alt=""
                    className="object-contain w-full"
                  />
                </div>

                <div className="flex -mx-2 mb-4">
                  <div className="flex flex-wrap -my-3 mb-5">
                    <div className="w-full max-w-full px-3 mb-6  mx-auto">
                      <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] border border-dashed border-stone-200 bg-white m-5">
                        <div className="flex-auto block py-8 px-9">
                          <div>
                            <div className="grid grid-cols-4 lg:grid-cols-4 gap-5">
                              {props.product.image &&
                                props.product.image.map((item, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col text-center lg:mr-8"
                                    onClick={
                                      () => handleThumbnailClick(item.image)
                                      // openLightbox(index)
                                    }
                                  >
                                    <div className="inline-block mb-3 relative shrink-0 rounded-[.25rem]">
                                      <img
                                        className="inline-block shrink-0 rounded-[.25rem]  cursor-pointer object-position"
                                        src={`${props.domain}${item.image}`}
                                        alt={`avatar_${index}`}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex-1 px-4 mx-auto">
              {props.product.name && (
                <h2 className="mb-2 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl">
                  {props.product.name}
                </h2>
              )}
              <div className="flex items-center space-x-4 my-4">
                <div>
                  <div className="rounded-lg bg-gray-100 py-2 px-3 flex flex-col">
                    {props.product.price && (
                      <span className="font-bold text-red-500 text-1xl line-through col-auto">
                        {props.properties.currencyUnit} {props.product.price_str}
                      </span>
                    )}
                    <span className="font-bold text-indigo-600 text-1xl col-auto">
                      {props.properties.currencyUnit}{" "}
                      {props.product.price_discount_str}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-gray-800">
                    {props.properties.numberInStock}
                  </span>
                  <span className="text-gray-700 font-medium">
                    {props.product.stock | 0}
                  </span>
                </div>
              </div>
              {props.product.description && (
                <p className="text-gray-500">{props.product.description}</p>
              )}
              <div className="mt-2">
                <h4 className="flex flex-row"><u className="mt-1 mr-2">4.9</u> <Star rating={5}/> <strong className="border-l-4 ml-2 px-2 mt-1">{props.product.count_like.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {props.properties.numberRating}</strong> <strong className="border-l-4 ml-2 px-2 mt-1">{props.product.count_sold | 0} {props.properties.numberSoldOfProduct}</strong></h4>
              </div>
              <div className="flex py-4 space-x-4">
                <div className="relative">
                  <div className="max-w-xs mx-auto">
                    <label
                      htmlFor="quantity-input"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                    >
                      {props.properties.chooseQuantity}:
                    </label>
                    <div className="relative flex items-center max-w-[12rem]">
                      <button
                        type="button"
                        className="border border-indigo-300 text-indigo-600 hover:text-white mr-2 hover:bg-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-sm px-4 py-2 bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                        onClick={() =>
                          props.setQuantity(Math.max(1, props.quantity - 1))
                        }
                      >
                        -
                      </button>
                      <input
                        type="text"
                        onChange={(e) =>
                          props.setQuantity(Number(e.target.value))
                        }
                        id="quantity-input"
                        value={props.quantity}
                        className="border border-indigo-300 text-center text-gray-900 focus:ring-indigo-500 rounded  focus:border-indigo-500 block w-11 px-3 py-2 bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="1"
                        required
                      />
                      <button
                        type="button"
                        className="border border-indigo-300 text-indigo-600 hover:text-white hover:bg-indigo-500 focus:ring-4 ml-2 focus:ring-indigo-300 font-medium rounded text-sm px-4 py-2 bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                        onClick={() => props.setQuantity(props.quantity + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => props.handleCartButtonClick()}
                  type="button"
                  className="h-14 px-6 py-2 font-semibold rounded-xl bg-indigo-600 hover:bg-indigo-500 text-white mt-4"
                >
                  {props.properties.buyNow}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
