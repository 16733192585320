import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Star from './Star';
import { Link } from 'react-router-dom';
import FilterTabs from './FilterTabs';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTiktok } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import contact_json from '../config_contact.json'
function ListProduct(props) {
 const [listProduct, setListProduct] = useState([]);
  const [data, setData] = useState([]);
 const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

 useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${props.properties.domain}/api/products/`);
        if (response.data.status) {
          // random list product
          var products = response.data.data;
          var data = products.sort(() => Math.random() - 0.5);
          setListProduct(data)
          setData(data)
        } else {
          window.location.href = props.properties.mainDomain;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData()
 }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center mb-32">
    <FilterTabs listProduct={listProduct} data={data} properties={props.properties} setListProduct={setListProduct}/>
    <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      <div className="relative m-3 flex flex-wrap mx-auto justify-center">
        {listProduct && listProduct.map((item, index) => (
          <div key={index} className="relative max-w-sm min-w-[340px] bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer">
            <Link to={`/${item?.slug}`} className="block w-full h-full" onClick={scrollToTop}>
              <div className="overflow-x-hidden rounded-2xl relative">
                <img className="max-h-60 rounded-2xl w-full object-cover"  src={`${props.properties.domain}${item.image[0].image}`} alt={`avatart_${index}`}/>
              </div>
              <div className="mt-4 pl-2 mb-2 flex justify-between ">
                <div>
                  <p className="text-lg font-semibold text-gray-900 mb-0">{item?.name}</p>
                  <span className="font-bold text-red-500 text-1xl line-through col-auto">
                        {props.properties.currencyUnit} {item?.price_str}
                  </span>
                  <span className="font-bold text-indigo-600 text-1xl col-auto ml-2">
                      {props.properties.currencyUnit}{" "}
                      {item.price_discount_str}
                    </span>
                  <p className='flex flex-row'><u className='mt-1 mr-3'>4.9</u> <Star rating={5}/></p>
                  <p className='mt-2'>
                    <strong className="mt-1">{item?.count_like.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {props.properties.numberRating}</strong> 
                    <strong className="border-l-4 ml-2 px-2 mt-1">{item?.count_sold | 0} {props.properties.numberSoldOfProduct}</strong>
                  </p>
                </div>
                
              </div>
              <div className='flex flex-col justify-center'>
                <button className="bg-red-600 text-white rounded-lg p-2">
                      {props.properties?.Add_to_cart}
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Link to={`tel:${contact_json.phoneNumberContact}`} className="phone-link">
        <FontAwesomeIcon icon={faPhone} />
      </Link>
      <Link to={contact_json.linkTiktok} className="tiktok">
        <FaTiktok />
      </Link>
      <Link to={contact_json.linkwhapapp} className="whatapp">
        <IoLogoWhatsapp />
      </Link>
      <Link to={contact_json.linkFace} className="facebook">
        <FaFacebook />
      </Link>
    </div>
  )
}

export default ListProduct
