import React, { useState } from 'react';
import {  NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLanguage
} from "@fortawesome/free-solid-svg-icons";
const Navbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [select, setLelect] = useState(1)
  const changeLanguage  = (lg) =>{
      props.changeLanguae(lg)
      setLelect(`${lg}`)
  }
  return (
    <nav className="bg-blue-100 shadow-lg fixed top-0 w-full z-30">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <NavLink to='/' className="flex-shrink-0 flex items-center">
            {/* Logo */}
            <img className="h-12 w-auto" src="https://1NPSHOP.COM/logo192.png" alt="Logo" />
          </NavLink>
          <div className="hidden sm:flex sm:items-center sm:space-x-8">
            {/* Navigation NavLinks */}
            <NavLink to='/' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{props.properties.home}</NavLink>
            <NavLink to='/invoice' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{props.properties.nameSearchInvoice}</NavLink>
            <NavLink to='/products' onClick={()=>setIsOpen(false)}  className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{props.properties.product}</NavLink>
            <NavLink to='/contact' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{props.properties.contact}</NavLink>
            <div  className="rounded-md text-sm font-medium m-auto flex flex-col" >  
                <div className="w-52">
                    <label htmlFor="exampleSelect" className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                    <FontAwesomeIcon icon={faLanguage} />  {props.properties.Selectlanguage}
                    </label>
                    <select
                  id="exampleSelect"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  onChange={(e) => changeLanguage(parseInt(e.target.value))}
                  value={select}
                >
                  <option value="1" className='text-black font-medium mt-2'>{props.properties.lao}</option>
                  <option value="2" className='text-black font-medium mt-2'>{props.properties.vietnam}</option>
                  <option value="3" className='text-black font-medium mt-2'>{props.properties.thailan}</option>
                  <option value="4" className='text-black font-medium mt-2'>{props.properties.Tienganh}</option>
                  <option value="5" className='text-black font-medium mt-2'>{props.properties.TrungQuoc}</option>
                </select>
                </div>
              </div>
          </div>
          {/* Mobile menu button */}
          <div className="flex items-center">
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="sm:hidden fixed top-0 left-0 w-full h-full bg-gray-300 bg-opacity-75 z-50">
          <div className="fixed top-0 left-0 h-full w-3/4 bg-blue-100 shadow-lg transform transition-transform ease-in-out duration-1000">
            <div className="px-2 pt-2 pb-3 space-y-1 text-center">
              {/* Mobile Navigation Links */}
              <NavLink to='/' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-600 hover:text-white block px-3 py-2 rounded-md text-xl font-bold">{props.properties.home}</NavLink>
              <NavLink to='/invoice' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-600 hover:text-white block px-3 py-2 rounded-md text-xl font-bold">{props.properties.nameSearchInvoice}</NavLink>
              <NavLink to='/products' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-600 hover:text-white block px-3 py-2 rounded-md text-xl font-bold">{props.properties.product}</NavLink>
              <NavLink to='/contact' onClick={()=>setIsOpen(false)} className="text-black hover:bg-gray-600 hover:text-white block px-3 py-2 rounded-md text-xl font-bold">{props.properties.contact}</NavLink>
              <div  className="rounded-md text-sm font-medium m-auto" style={{display:'flex', justifyContent:"center"}}>
                <div className="w-52">
                    <label htmlFor="exampleSelect" className="block  text-black text-xl font-bold">
                    <FontAwesomeIcon icon={faLanguage} /> {props.properties.Selectlanguage}
                    </label>
                    <select
                  id="exampleSelect"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  onChange={(e) => changeLanguage(parseInt(e.target.value))}
                  value={select}
                >
                  <option value="1" className='text-black font-medium mt-2'>{props.properties.lao}</option>
                  <option value="2" className='text-black font-medium mt-2'>{props.properties.vietnam}</option>
                  <option value="3" className='text-black font-medium mt-2'>{props.properties.thailan}</option>
                  <option value="4" className='text-black font-medium mt-2'>{props.properties.Tienganh}</option>
                  <option value="5" className='text-black font-medium mt-2'>{props.properties.TrungQuoc}</option>
                </select>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed top-0 right-0 h-full w-1/4  shadow-lg transform transition-transform ease-in-out duration-1000">
            <button onClick={() => setIsOpen(!isOpen)} className="text-black p-4">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
