import React, { useEffect, useState } from "react";
import ProductDetail from "./ProductDetail";
import { FaTiktok } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
// import Rating from "./Rating";
import Comment from "./Comment";
import FormBuy from "./FormBuy";
import CountDow from "./CountDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faDownLong,
  faUpLong,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Carousel from "./Carousel";
import contact_json from '../config_contact.json'
import ListProduct from "./ListProduct";


function Home(props) {
  const { slug } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [productInput, setProductInput] = useState({
    id: "",
    price: "",
  });
  const [event, setEvent] = useState("");

  const getQuantity_product = () => {
    const data = {
      quantity: quantity,
      product: productInput,
    };
    return data;
  };

  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [product, setProduct] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  // State to track the main image
  const [mainImage, setMainImage] = useState(
    product.image && product.image[0]?.image
      ? `${props.properties.domain + product.image[0].image}`
      : ""
  );
  const handleScroll = () => {
    const currentPosition = window.pageYOffset;
    setIsVisible(currentPosition > 300); // Hiển thị nút khi cuộn trang xuống dưới 300px
    setIsScrollingUp(currentPosition > 300); // Kiểm tra xem trang có thể cuộn xuống được không
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleCartButtonClick = () => {
    const cartElement = document.getElementById("cart"); // Lấy phần tử có id là "cart"
    if (cartElement) {
      cartElement.scrollIntoView({ behavior: "smooth" }); // Cuộn đến phần tử "cart" một cách mượt
    }
  };

  // API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${props.properties.domain}/api/product/${slug}`);
        console.log(response)
        if (response.data.data.name) {
          setProduct(response.data.data);
          setMainImage(
            response.data.data.image && response.data.data.image[0]?.image
        ? `${props.properties?.domain + response.data.data.image[0].image}`
        : ""
          )
          setProductInput({
            id: response.data.data.id,
            price: response.data.data.price,
          });
          setEvent(response.data.event[0]);
          setIsProduct(false)
        } else {
          setIsProduct(true)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchAllData = async () => {
      try {
        const response = await axios.get(
          `${props.properties.domain}/api/products/?except=${slug}`
        );
        if (response.data.status) {
          setListProduct(response.data.data);
        } else {
          window.location.href = props.properties.mainDomain;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchAllData();
  }, [slug, isProduct]);

  if (slug === undefined) {
    return (
      <div className="container m-auto">
        <div className="flex flex-col items-center justify-center bg-gray-100 bg-center bg-no-repeat h-screen mx-8">
          <Carousel listProduct={listProduct} domain={props.properties.domain} config={props.properties} scrollToTop={scrollToTop} />
          <Link href={`tel:${props.properties.phoneNumberContact}`} className="phone-link">
            <FontAwesomeIcon icon={faPhone} />
          </Link>
        </div>
      </div>
    );
  }

  if (isProduct){
    return(
      <ListProduct properties={props.properties}/>
    )
  }

  return (
    <div>
      <ProductDetail
        handleCartButtonClick={handleCartButtonClick}
        domain={props.properties.domain}
        product={product}
        setQuantity={setQuantity}
        quantity={quantity}
        mainImage={mainImage}
        properties={props.properties}
        setMainImage={setMainImage}
      />
      {/* rating */}
      {/* <Rating properties={props.properties}/> */}
      {/* comments */}
      <Comment properties={props.properties} domain={props.properties.domain} comment={product.comment} />
      {/* count dow */}
      <CountDow event={event} />
      {/* mua hang */}
      <FormBuy
        product={product}
        listProduct={listProduct}
        properties={props.properties}
        scrollToTop={() => scrollToTop()}
        domain={props.properties.domain}
        getQuantity_product={getQuantity_product}
        setQuantity={setQuantity}
      />
      {/* Nút bấm lên */}
      {isVisible && (
        <>
          <button className="scroll-to-top" onClick={()=> scrollToTop()}>
            <FontAwesomeIcon icon={faUpLong} />
          </button>
        </>
      )}
      {isScrollingUp && (
        <button className="scroll-to-bottom" onClick={()=>scrollToBottom()}>
          <FontAwesomeIcon icon={faDownLong} />
        </button>
      )}
      <Link to={`tel:${contact_json.phoneNumberContact}`} className="phone-link">
        <FontAwesomeIcon icon={faPhone} />
      </Link>
      <Link to={contact_json.linkTiktok} className="tiktok">
        <FaTiktok />
      </Link>
      <Link to={contact_json.linkwhapapp} className="whatapp">
        <IoLogoWhatsapp />
      </Link>
      <Link to={contact_json.linkFace} className="facebook">
        <FaFacebook />
      </Link>
      <button className="cart" onClick={handleCartButtonClick}>
        <FontAwesomeIcon icon={faCartShopping} />
      </button>
    </div>
  );
}

export default Home;
