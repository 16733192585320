import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Invoice from './components/invoice';
import Navbar from './components/Navbar';
import TitleSale from './components/TitleSale';
import Footer from './components/Footer';
import ListProduct from './components/ListProduct';
import { useState } from 'react';
import properties from './config';
import Vn from './configVN.json';
import Tl from './configTL.json';
import En from './configEN.json';
import Tq from './configTQ.json'
import Contact from './components/Contact';
import SpinWheel from './components/SpinWheel';
function App() {
  const [language, setLanguage] = useState(properties);
  const changeLanguae = (number) =>{
    switch(number){
      case 1: 
        setLanguage(properties);
        break;
      case 2:
        setLanguage(Vn);
        break
      case 3:
        setLanguage(Tl);
        break
      case 4:
        setLanguage(En);
        break
      case 5:
        setLanguage(Tq);
        break
      default:
        setLanguage(properties)
    }
  }
  
  return (
    <Router>
      <Navbar properties={language} changeLanguae={changeLanguae}/>
      <TitleSale/>
        <Routes>
          <Route exact path="/invoice" element={<Invoice properties={language}/>} />
          <Route exact path="/:slug" element={<Home properties={language}/>} />
          {/* <Route exact path="/" element={<Home properties={language}/>} /> */}
          <Route exact path="/" element={<ListProduct properties={language}/>} />
          <Route exact path='/products' element={<ListProduct properties={language}/>}/>
          <Route exact path='/contact' element={<Contact/>}/>
          {/* <Route exact path='/spin' element={<SpinWheel/>}/> */}
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
