import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilter
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';

const Tab = ({ name, isActive, onClick }) => (
  <button
    className={`px-4 py-2 text-sm font-medium text-gray-700 rounded-t-lg border-b-2 
                ${isActive ? 'border-blue-500 text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'}`}
    onClick={onClick}
  >
    {name}
  </button>
);

const FilterTabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.properties?.price);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [value, setValue] = useState([0, 150000]);
  const handleChange = (event) => {
    console.log(event.target.value)
    if (event.target.value){
      setSearchTerm(event.target.value);
      performSearch()
    }else{
      setSearchResults([])
      setSearchTerm('')
    }
  };
  // tính toán giá
  const priceFilter = () =>{
      if (parseFloat(value[0]) > parseFloat(value[1])){
          toast.error("Giá bắt đầu phải nhỏ hơn giá kết thúc")
      }else{
          let newdata = [...props.data];
          newdata = newdata.filter(item => parseFloat(item.price) <= parseFloat(value[1]) && parseFloat(item.price) >= parseFloat(value[0]))
          props.setListProduct(newdata)
          if(!newdata.length){
            toast.warning("Không tìm thấy sản phẩm nào")
          }
      }
  }
  

  // Tính toán kết quả tìm kiếm liên quan dựa trên giá trị nhập vào
  const performSearch = () => {
    const results = props.listProduct?.filter(item => item.name.includes(searchTerm));
    setSearchResults(results);
  };

  const tabs = [props.properties.price, props.properties.search, props.properties.category];

  return (
    <div className="flex flex-col items-center">
        
      <div className="flex space-x-1 rounded-lg bg-white p-2">
      <h1 className='m-3 font-semibold'><FontAwesomeIcon className='h-6 text-gray-400' icon={faFilter} /> </h1>
        {tabs.map((tab) => (
          <Tab
            key={tab}
            name={tab}
            isActive={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          />
        ))}
      </div>
          {
            activeTab && (
              <div className="bg-white p-4 w-80 rounded-lg shadow-md mt-2">
              {activeTab === props.properties.price && (
                  <div className="flex flex-col py-4">
                  <div className="flex justify-between text-xs px-2 mb-2">
                    <span>{value[0].toLocaleString('vi-VN')} VND</span>
                    <span>{value[1].toLocaleString('vi-VN')} VND</span>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="range"
                      min="54070"
                      max="995000"
                      value={value[0]}
                      onChange={(e) => setValue([Number(e.target.value), value[1]])}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    />
                    <input
                      type="range"
                      min="54070"
                      max="995000"
                      value={value[1]}
                      onChange={(e) => setValue([value[0], Number(e.target.value)])}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    />
                  </div>
                  <button
                    className="mt-4 bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>priceFilter()}
                  >
                    ຄັກເລືອກ
                  </button>
                </div>
                  )}
                  {activeTab === props.properties.search && (
                  <div>
                      <div className="m-4">
                  <input
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      type="text"
                      placeholder={props.properties.search}
                      value={searchTerm}
                      onChange={handleChange}
                  />
    
                  <div className="mt-4">
                      <ul className='z-30'>
                      {searchResults.map((item, index) => (
                          <li key={index} >
                            <Link to={`/${item?.slug}`} className='flex flex-row m-3 border-b-2'>
                              <img className='w-12 mb-2 rounded-lg' src={`${props.properties.domain}${item.image[0].image}`} alt={`avatart_${index}`} />
                              <strong className='ml-3 text-blue-500'>{item.name}</strong>
                            </Link>
                          </li>
                          
                      ))}
                      </ul>
                  </div>
                  </div>
                  </div>
              )}
              {activeTab === props.properties.category && (
                <div>
                  {/* Repeat for other tabs */}
                  {/* Checkbox components would go here */}
                </div>
              )}
            </div>
            )
          }
    </div>
  );
};

export default FilterTabs;
